@import '../../styles/mixins.module.scss';
@import '../../styles/colors.module.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease-out;
  pointer-events: none;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .content {
    position: absolute;
    overflow: auto;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 201;
    width: 300px;
    background-color: $color-white;
    transition: transform 300ms ease-out;

    &.fromLeft {
      transform: translate3d(-100%, 0, 0);
    }

    &.fromRight {
      transform: translate3d(100%, 0, 0);
    }

    &.fromBottom {
      transform: translate3d(0, 100%, 0);
    }

    &.fromTop {
      transform: translate3d(0, -100%, 0);
    }

    &.fullPage {
      width: 100%;
    }
  }

  &.enter {
    opacity: 1;
    pointer-events: initial;

    .content {
      transform: translate3d(0, 0, 0);
    }
  }
}

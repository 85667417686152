@import '../../styles/colors.module.scss';
@import '../../styles/theme.module.scss';

.loading {
  width: 64px;
  height: 64px;
  @include theme() {
    fill: themeColor('accent');
    stroke: themeColor('accent');
  }
}

@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';

.agreementList {
  margin: 20px auto 40px;
  padding: 0 10px;

  .agreement {
    color: $color-grey0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;

    label {
      font-size: $sizeML;
      display: block;
    }
  }
}

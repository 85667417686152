@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.titleImage {
  display: block;
  width: 124px;
  margin: 10px auto 0;
}

.alreadyHaveAccount {
  text-align: center;
  font-size: $sizeML;
  color: var(--color-neutral-4);
  margin-top: -18px;
  margin-bottom: 15px;
}

.termsAndConditions {
  margin: 20px 0;
  text-align: center;
  font-size: $sizeM;
  color: $color-grey0;
}

.disclaimer {
  color: $color-grey4;
  text-align: center;
  padding-top: 20px;
  font-size: $sizeS;
  line-height: 1.3;
}

.sheetContainer {
  margin: 20px auto;
  max-width: 500px;

  .sheetButton {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}

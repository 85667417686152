@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  text-align: center;
  @include secondaryFont();
  width: fit-content;
  margin: 20px auto;
  padding: 0;

  .item {
    margin-bottom: 10px;
    list-style-type: none;
  }
}

/*
 * Brand colors
 * CSS custom properties (aka CSS variables)
 */
:root {
  --color-primary-1: #3f080e;
  --color-primary-2: #5b0b25;
  --color-primary-3: #881137;
  --color-primary-4: #b71548;
  --color-primary-5: #003DA5;
  --color-primary-6: #e84a7c;
  --color-primary-7: #f18ead;
  --color-primary-8: #ffccdd;
  --color-primary-9: #ffe5ee;
  --color-primary-10: #fff5f8;
  --color-secondary-1: #001d1f;
  --color-secondary-2: #083336;
  --color-secondary-3: #0c5155;
  --color-secondary-4: #08767d;
  --color-secondary-5: #00a9b3;
  --color-secondary-6: #0bc8d1;
  --color-secondary-7: #63e3e9;
  --color-secondary-8: #b2f7fa;
  --color-secondary-9: #d6fdff;
  --color-secondary-10: #ebfeff;
  --color-neutral-1: #131c20;
  --color-neutral-2: #1e292f;
  --color-neutral-3: #303e45;
  --color-neutral-4: #475861;
  --color-neutral-5: #62757f;
  --color-neutral-6: #889ba5;
  --color-neutral-7: #b1c0c8;
  --color-neutral-8: #d9e3e8;
  --color-neutral-9: #edf0f2;
  --color-neutral-10: #f9fafb;
  --color-error-1: #390505;
  --color-error-2: #550707;
  --color-error-3: #781111;
  --color-error-4: #9c1616;
  --color-error-5: #c12525;
  --color-error-6: #e24b4b;
  --color-error-7: #e96767;
  --color-error-8: #f39595;
  --color-error-9: #f9c8c8;
  --color-error-10: #fef0f0;
  --color-success-1: #053d2b;
  --color-success-2: #07553b;
  --color-success-3: #117856;
  --color-success-4: #1a936b;
  --color-success-5: #20b684;
  --color-success-6: #4dcba1;
  --color-success-7: #67e4bb;
  --color-success-8: #9df1d5;
  --color-success-9: #bbfbe6;
  --color-success-10: #d1e7dd;
  --color-white: #fff;
  --color-black: #000;

  --primary-blue: #003da5;
  --secondary-cyan: #00a3d7;

  --primary-gray: #414042;
  --utility-gray: #b8babc;

  --semantic-blue: #0062a6;
  --semantic-pale-blue: #e6f0f6;
  --semantic-green: #2a7000;
  --semantic-pale-green: #eff5e6;
  --semantic-orange: #be5000;
  --semantic-pale-orange: #fcf6ee;
  --semantic-red: #aa0008;
  --semantic-pale-red: #f7e6e7;
}

/*
 * Legacy colors (to be removed)
 * These are not 1:1 matches to the new brand colors and should not be used for new UI
 * TODO: Manually replace all of these with their color matched equivalent newer brand colors
 */
$color-accent: #003da5;
$color-accent-darker: #003da5;
$color-accent-light: #e6f0f6;
$color-error: #dd0000;
$color-valid: #45c095;
$color-warning1: #fffddd;
$color-warning2: #fca54e;
$color-grey0: #545759;
$color-grey1: #676b6d;
$color-grey2: #ededed;
$color-grey3: #d8d8d8;
$color-grey4: #979799;
$color-grey5: #f9f9f9;
$color-grey6: #bfbfbf;
$color-grey7: #fbfbfb;
$color-grey8: #f6f5f5;
$color-grey9: #d7d7d7;
$color-grey10: #f8f8fa;
$color-grey11: #283142;
$color-gray1: #333333;
$color-gray2: #555555;
$color-gray3: #b3b3b3;
$color-gray4: #e0e0e0;
$color-gray5: #f0f0f0;
$color-white: #ffffff;
$color-black: #222222;
$color-yellow1: #f9f7d1;
$color-yellow2: #f5cf23;
$color-yellow3: #dc9909;
$color-red1: #d80404;
$color-red2: #f4b0b0;
$color-text: #4e585c;
$color-input-text-focused: #4e585c;
$color-input-text: #4e585c;
$color-input-label: #959799;
$color-background: #ffffff;
$input-border: #bfbfbf;
$input-background: #ffffff;
$text-unselected-profile: #9b9b9b;
$color-gold-header: #cec5b9;
$color-gold-content: #f6f5f5;
$color-gold-primary-clickable: #ededed;
$color-gold-secondary-clickable: #fbfbfb;

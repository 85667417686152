@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.dragContainer {
  position: relative;
  outline: none;

  .dragOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }
}

.filesContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  .fileThumbnail {
    cursor: pointer;
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-clip: content-box;
    background-size: cover;
    border: 1px solid #979797;

    .success {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .error {
      margin: 0 auto;
      display: block;
      margin-top: 25px;
    }

    .loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;

      svg,
      svg * {
        stroke: var(--color-neutral-7);
      }
    }
  }
}

.fileUploadBox {
  border: 1px dashed $color-grey1;
  background-color: $color-grey5;
  text-align: center;
  margin-top: 10px;
  padding: 40px 20px;

  .title {
    font-weight: bold;
  }

  .or {
    font-weight: bold;
    color: $color-grey1;
    margin: 10px;
  }

  .button {
    width: 150px;
    display: inline-block;
    text-align: center;
    @include theme() {
      background-color: themeColor('accent');
    }
    color: white;
    border-radius: 20px;
    margin: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    cursor: pointer;
  }

  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:is(:hover, :active, :focus) + .button {
      @include theme() {
        background-color: themeColor('accentDark');
      }
    }
  }

  .fileTypes,
  .fileSizeLimit {
    font-size: $sizeM;
    color: $color-grey4;
  }

  .fileTypes {
    margin-top: 15px;
  }

  .fileSizeLimit {
    margin-top: 3px;
  }
}

.bottomSheetContent {
  text-align: center;
  padding: 30px 20px 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;

  button {
    display: block;
    width: 100%;

    &:first-child {
      margin-bottom: 20px;
    }
  }
}

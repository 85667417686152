@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  margin: 0 auto;
  max-width: 400px;

  .image {
    text-align: center;
  }

  .name {
    @include primaryFont(false);
    font-size: $sizeL;
    text-align: center;
    @include theme() {
      color: themeColor('accent');
    }
  }

  .bio {
    @include primaryFont(false);
    font-size: $sizeML;
    margin-top: 10px;
    height: 270px;
    overflow: auto;
  }
}

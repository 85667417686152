$break-mobile: 480px;

@mixin small-mobile {
  @media only screen and (max-device-width: 320px) and (orientation: portrait) {
    @content;
  }
}

@mixin small-320 {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $break-mobile) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $break-mobile + 1) {
    @content;
  }
}

$base-font-size: 14px !default;

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'), url($file-name + '.woff') format('woff'), url($file-name + '.ttf') format('truetype'),
      url($file-name + '.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin font-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }

  font-size: $size;
  font-size: ($size / $base-font-size) * 1rem;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $spread, $color) {
  -webkit-box-shadow: $top $left $blur $spread $color;
  -moz-box-shadow: $top $left $blur $spread $color;
  box-shadow: $top $left $blur $spread $color;
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top, $from, $to);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin grayscale($value: 100%) {
  -webkit-filter: #{'grayscale(#{$value})'};
  -moz-filter: #{'grayscale(#{$value})'};
  filter: #{'grayscale(#{$value})'};
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($str) {
  -webkit-transition: #{$str};
  -moz-transition: #{$str};
  -ms-transition: #{$str};
  -o-transition: #{$str};
  transition: #{$str};
}

@mixin transform($str) {
  -webkit-transform: #{$str};
  -moz-transform: #{$str};
  -ms-transform: #{$str};
  -o-transform: #{$str};
  transform: #{$str};
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  margin: 30px auto 0;
  max-width: 280px;
  padding-bottom: 20px;
  @include desktop {
    max-width: 356px;
  }

  .visitSummaryContainer {
    background-color: $color-grey8;
    text-align: center;
    padding: 15px;

    .title {
      @include secondaryFont(true);
      font-size: $sizeML;
    }

    .time {
      @include secondaryFont();
      font-size: $sizeML;
    }
  }
  .quickPickContainer {
    .text {
      @include primaryFont();
      font-size: $sizeML;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .secondaryButton {
      background-color: $color-white;
      @include theme() {
        border-color: themeColor('accent');
        color: themeColor('accent');
      }
    }

    .button,
    .secondaryButton {
      margin-bottom: 15px;
    }
  }

  .pickTimeslotContainer {
    .text {
      @include primaryFont();
      font-size: $sizeML;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .rescheduleButton {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .thankYouContainer {
    text-align: center;
    margin-bottom: 15px;

    .image {
      width: 50px;
    }

    .textContainer {
      margin-top: 10px;
      margin-bottom: 30px;

      .text {
        @include primaryFont();
        color: $color-grey0;
        font-size: $sizeL;
        margin: 0;
      }
    }
  }

  .errorContainer {
    background-color: #fffddd;
  }

  .tryAgainButton {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

@import '../../../styles/colors.module.scss';
@import '../../../styles/fonts.module.scss';
@import '../../../styles/mixins.module.scss';
@import '../../../styles/theme.module.scss';

.menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &.unauthorized {
    padding-top: 15px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
  }

  .header {
    height: 100px;
    width: 100%;
    border-bottom: 1px solid $color-grey3;
    margin-bottom: 15px;
    padding-bottom: 10px;
    background-color: $color-background;
    z-index: 200;

    .nameLabel {
      display: inline-block;
      font-size: $sizeXXL;
      @include primaryFont();
      font-weight: 600;
      padding-left: 10px;
      padding-top: 16px;
    }

    .avatarContainer {
      padding-left: 15px;
      margin: 0 auto;
      padding-top: 20px;
    }
  }

  .content {
    .menuItem {
      display: block;
      padding-left: 15px;
      padding-bottom: 15px;
      color: $color-grey0;
      @include secondaryFont();
      font-size: $sizeXL;
      line-height: 30px;
      @include grayscale();
      cursor: pointer;

      &.active {
        @include grayscale(0);
        @include theme() {
          color: themeColor('accent');
        }
      }

      .menuIcon {
        float: left;
        width: 35px;
        line-height: 36px;
      }
    }

    .logout {
      width: 100%;
      border-top: 1px solid $color-grey3;
      padding-top: 15px;
    }
  }

  .footer {
    margin: auto 20px 30px;

    .badges {
      margin: 0 -5px 10px;
    }

    .copyrightFooter {
      font-size: $sizeXS;
      color: $color-grey0;
    }
  }
}

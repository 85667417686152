@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  &.selected {
    .circle {
      @include theme() {
        border-color: themeColor('accent');
      }
      background-color: $color-white;

      .initialsText {
        @include theme() {
          color: themeColor('accent');
        }
      }
    }
  }

  .nameContainer {
    text-align: center;

    .nameText {
      font-size: $sizeM;
      @include text-overflow();
      white-space: nowrap;
      @include primaryFont();
    }

    .prefixText {
      /**/
    }
  }

  .circle {
    border: 1px solid $color-white;
    background-color: $color-grey2;
    font-size: $sizeML;
    @include rounded(50%);
    @include secondaryFont(true);

    .initialsText {
      /**/
    }
  }
}

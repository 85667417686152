@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  border: 1px solid $color-grey3;
  @include rounded(5px);
  padding: 20px;

  .name {
    display: inline;
    @include primaryFont(true);
  }
}

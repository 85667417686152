@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.preferredDoctorHeader {
  text-align: left;
  min-height: 50px;
  margin: 10px 0px 20px 20px;
  color: $color-grey1;

  .avatar {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    background-color: $color-grey2;
    color: $color-grey1;
    line-height: 50px;
    text-align: center;
    font-size: $sizeL;
    font-weight: bold;
    margin: 0 10px;
    float: left;
  }
}

.container {
  .listItem {
    display: block;
    cursor: pointer;
    border: 1px solid $color-grey3;
    @include rounded(5px);
    background-color: $color-white;
    margin-bottom: 5px;

    &.active {
      background-color: $color-white;
      @include theme() {
        border-color: themeColor('accent');
      }

      .image {
        background: $color-white;
      }

      .textContainer {
        .textTitle {
          @include primaryFont(true);
        }
      }
    }

    .image {
      vertical-align: middle;
      text-align: center;
      background: $color-grey7;
      width: 70px;
      height: 80px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .textContainer {
      vertical-align: middle;
      padding: 0 10px;

      .textTitle {
        color: $color-grey1;
        @include primaryFont();
        font-size: $sizeL;
      }

      .textSubtitle {
        color: $color-grey1;
        @include primaryFont();
        font-size: $sizeM;
      }
    }
  }
}

.asap {
  margin-top: 20px;
}

.unfocus {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.asapTimeslot {
  width: 100%;
  background: $color-grey7;

  .asapTimeslot-header {
    background: $color-white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $color-white;
  }
}

.pageDivider {
  text-align: center;
  margin-top: 20px;

  .pageDivider-line {
    width: 100%;
    border-bottom: 1px solid $color-gray2;
    line-height: 0.1em;
    margin: 10px 0 20px;

    .pageDivider-text {
      background: $color-white;
      padding: 0 10px;
      color: $color-gray2;
    }
  }
}

.notice {
  @include theme() {
    background-color: themeColor('accentLight');
  }
  padding: 15px 20px;
  border-radius: 5px;
  color: $color-text;
  font-size: 14px;
  margin-bottom: 20px;

  .header {
    @include theme() {
      color: themeColor('accent');
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .title {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      display: flex;
      align-items: center;

      .icon {
        width: 20px;
        margin-right: 6px;
      }
    }

    .action {
      @include theme() {
        color: themeColor('accent');
      }
      cursor: pointer;

      &:hover {
        @include theme() {
          color: themeColor('accentDark');
        }
      }
    }
  }
}

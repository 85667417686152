@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;

  .image {
    max-height: 240px;
    max-width: 140px;
  }

  .title {
    font-size: $sizeXXL;
    margin: 2px;
    color: var(--color-neutral-3);
  }

  .subtitle {
    font-size: $sizeML;
    font-weight: normal;
    color: var(--color-neutral-4);
    margin: 0;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.img {
  width: 160px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.title {
  color: #444;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.subtitle {
  color: #888;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.content {
  margin: 30px 0;

  .description {
    color: #888;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.actionButton {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.btnLink {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.patientName {
  color: $color-grey0;
  font-size: 16px;
}

.selectedPatients {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -15px;

  .selectedPatient {
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 99px;
      background-color: $color-grey2;
      color: $color-grey1;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .patientName {
      color: $color-grey1;
      font-size: 12px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.error {
        color: $color-yellow3;
      }

      &.success {
        color: $color-valid;
      }

      img {
        margin-right: 4px;
        height: 14px;
        width: auto;
      }
    }
  }
}

.section {
  margin: 40px 0;
  text-align: center;

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey0;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;

    .icon {
      height: 15px;
      width: auto;
      margin-right: 5px;
    }
  }
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 15px 30px 0;

  .label {
    font-size: 12px;
    font-weight: 700;
    color: $color-grey0;
    text-transform: uppercase;
  }

  .value {
    font-size: 16px;
    color: $color-accent;
    font-weight: 700;
    text-align: right;
  }
}

.payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .creditCard {
    flex-grow: 1;
    margin-right: 30px;
  }

  .total {
    display: flex;
    flex-direction: column;
    align-items: center;

    .value {
      color: $color-accent;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 575px) {
    .creditCard {
      width: 100%;
      margin-right: 0;
    }

    .total {
      width: 100%;
    }
  }
}

.checkmark {
  width: 40px;
  display: block;
  margin: 0 auto 15px;
}

.divider {
  border-top: 1px solid $color-grey2;
  margin: 30px 0;
}

.contactSupport {
  font-size: 14px;
  text-align: center;
  color: $color-grey1;
}

.errorBox {
  padding-top: 20px;
}

@import '../../styles/fonts.module.scss';

.container {
  margin: 0px auto 20px auto;
  max-width: 380px;
  padding: 10px;

  .textContainer {
    margin-top: 0;
  }

  .title {
    font-size: $sizeL;
    text-align: center;
    margin-bottom: 10px;
  }

  .label {
    display: inline-block;
    font-size: 14px;
    padding: 5px 3px;
    color: var(--color-neutral-5);
    &.required {
      &::after {
        content: '*';
        position: relative;
        left: 1px;
        top: -2px;
        font-size: 75%;
        color: var(--color-error-5);
      }
    }
  }

  .emergency {
    font-size: $sizeS;
    text-align: center;
  }

  .reasonButton {
    margin-top: 20px;
    width: 100%;
  }
}

.guardianContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .label {
    font-size: $sizeM;

    .qmark {
      width: 18px;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      vertical-align: middle;
      cursor: pointer;

      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}

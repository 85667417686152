@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/theme.module.scss';

.content {
  color: $color-grey0;
  font-size: $sizeML;
}

.header {
  text-align: center;

  .titleImage {
    width: 124px;
    height: 124px;
    margin: 10px auto 0;

    img {
      width: 124px;
      height: 124px;
    }
  }

  .info {
    @include secondaryFont();
  }
}

.secondaryInfo {
  @include primaryFont();
  font-size: $sizeML;
  color: $color-grey0;
  padding-top: 20px;
}

.errorBox {
  margin-top: 10px;
}

.secondaryButton {
  margin-top: 20px;
  background-color: $color-white;
  @include theme() {
    border-color: themeColor('accent');
    color: themeColor('accent');
  }
}

.action {
  margin-top: 20px;
}

.forgotPassword {
  padding-top: 10px;
  cursor: pointer;
  outline: 0;
  @include theme() {
    color: themeColor('accent');
  }
  @include secondaryFont();
}

.border {
  z-index: 1;
  margin-top: 20px;
  position: relative;
  text-align: center;

  &:before {
    border-top: 1px solid $color-grey9;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .text {
    @include secondaryFont();
    font-size: $sizeML;
    background: #fff;
    color: $color-grey0;
    padding: 0 10.5px;
  }
}

.darkTitle {
  font-size: $sizeXXL;
  padding-top: 20px;
  color: $color-grey0;
  @include secondaryFont();
}

.skipLinkingButton {
  @include theme() {
    color: themeColor('accent') !important;
    border: 1px solid themeColor('accent') !important;
  }
  background-color: $color-white !important;
}

.footer {
  text-align: center;
  font-size: $sizeM;
  color: $color-grey0;
}

.privacyText {
  color: $color-grey0;
  text-align: center;

  a {
    @include theme() {
      color: themeColor('accent');
    }
  }
  margin-bottom: 20px;
}

.missingPhone {
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: #e6e6e6;
  padding: 20px 20px 30px 20px;
  margin-bottom: 20px;

  .title {
    text-align: center;
    font-size: $sizeXXL;
  }

  .subtext {
    @include primaryFont();
    font-size: $sizeML;
    color: $color-grey0;
    text-align: center;
    margin-top: 10px;
  }

  .phoneField {
    margin-top: 10px !important;
  }
}

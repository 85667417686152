@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/mixins.module.scss';

.content {
  margin: 20px auto;
  max-width: 280px;

  .header,
  .email,
  .success,
  .support {
    @include primaryFont();
    font-size: $sizeML;
    margin-bottom: 20px;
    color: $color-grey0;
    text-align: center;
  }

  .email {
    @include primaryFont(true);
    font-size: $sizeL;
  }

  .success {
    height: 40px;
  }

  .button {
    margin-bottom: 20px;
  }

  .support {
    font-size: $sizeS;
    margin: 0 20px;
  }
}

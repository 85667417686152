@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.sheetContainer {
  margin: 20px auto;
  max-width: 280px;

  .sheetButton {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}

.preferredDoctor {
  display: flex;
  align-items: center;

  .name {
    margin-left: 10px;
  }
}

.insuranceFields {
  display: flex;
  gap: 10px;
}

.infoButton {
  height: 16px;
  width: 16px;

  svg {
    width: 16px;
    height: 16px;

    & * {
      fill: var(--color-neutral-6);

      &:hover {
        fill: var(--color-neutral-5);
      }
    }
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  text-align: center;

  .image {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .text {
    font-size: $sizeL;
    font-weight: 500;
    color: var(--color-neutral-4);
  }

  .subtext {
    font-size: $sizeML;
    font-weight: normal;
    color: var(--color-neutral-4);
    margin-bottom: -10px;
  }

  .button {
    margin-top: 60px;
    margin-bottom: 10px;
  }
}

.image {
  text-align: center;
  margin-bottom: 20px;
}

.sheetHeading {
  margin: 0 auto 20px;
  width: 80%;
  text-align: center;
}

.button {
  margin-top: 40px;
}

.fluShots {
  max-height: calc(100% - 10px) !important;
}

.fluShotsContent {
  @media only screen and (max-height: 800px) {
    width: calc(100% - 20px) !important;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.fluShotsInner {
  margin: 0 60px 20px;

  @media only screen and (max-width: 575px) {
    margin: 0 15px 20px;
  }
}

@import '../../../styles/colors.module.scss';
@import '../../../styles/fonts.module.scss';
@import '../../../styles/mixins.module.scss';
@import '../../../styles/theme.module.scss';

$topNavHeight: 60px;

.topNav {
  height: $topNavHeight;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey3;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .left,
  .middle,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    justify-content: flex-start;
    padding-left: 4px;
    flex: 1 1;
  }

  .middle {
    justify-content: center;
    flex: 1 0;
  }

  .right {
    justify-content: flex-end;
    flex: 1 1;
  }

  .logo {
    position: relative;
  }

  .textButton {
    margin-right: 5px;
    text-align: right;
  }

  .backButton {
    height: 40px;
    width: 40px;
    text-align: center;
  }

  &.hideMenu {
    border-bottom: 0;
  }
}

.flyOut {
  position: fixed;
  top: $topNavHeight;
  right: 0;
  left: 0;
  bottom: 0;

  /*
  * Prevent Zendesk widget from blocking side menu text in the bottom right (widget has z-index: 999998).
  * However, an open Zendesk chat window will still float ontop of menu.
  */
  z-index: 999999;
  pointer-events: none;
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  .titleImage {
    text-align: center;
    width: 124px;
    height: 124px;
    margin: 10px auto 0;

    img {
      width: 124px;
      height: 124px;
    }
  }

  .forgot {
    margin-top: 15px;
    text-align: center;
    font-size: $sizeM;
  }

  .noAccountContainer {
    margin-top: 15px;
    color: $color-grey1;
    text-align: center;
    .text {
      font-size: $sizeM;
    }
  }

  .contactUsContainer {
    font-size: $sizeM;
    color: $color-grey1;
    margin: 40px auto 0 auto;
    text-align: center;
  }
}

.submitButton {
  margin-top: 20px;
}

.blueshieldButton {
  border-color: #007acc;
  color: #007acc !important;
  background-color: white !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.depth {
  background-color: $color-white;
  overflow: auto;
  width: 100%;
  @include mobile {
    font-size: $sizeM;
  }

  .card {
    border-bottom: 1px solid $color-gray4;
    margin: 10px 68px 20px 68px;
    padding-bottom: 10px;
    @include secondaryFont();
    @include mobile {
      margin: 10px 30px 10px 30px;
      padding-top: 5px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    .action {
      margin: 10px 50px 20px 50px;
      @include mobile {
        margin-bottom: 10px;
        max-width: 200px;
        margin: 0 auto;
      }
    }

    .bulletContainer {
      padding-left: 10px;
      padding-bottom: 10px;
      margin: auto;
      display: block;
      @include mobile {
        padding-left: 5px;
        padding-bottom: 5px;
      }

      .bullet {
        color: $color-grey11;
        display: table-cell;
        padding-left: 10px;
        vertical-align: middle;
        padding-top: 10px;
        @include mobile {
          padding-top: 2px;
          color: #283142;
        }
      }
    }

    .insuranceLogo {
      display: inline;
      margin: 0 auto;
      @include mobile {
        display: none;
      }
    }

    .mobileInsuranceLogo {
      display: none;
      @include mobile {
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
      }
    }

    .text {
      color: $color-grey11;
      text-align: center;
      padding-bottom: 20px;
      @include mobile {
        padding-bottom: 10px;
      }

      .textAuthor {
        margin-top: -3px;
      }
    }

    .disclaimer {
      color: $color-grey4;
      text-align: center;
      padding-bottom: 20px;
      font-size: $sizeS;
      line-height: 1.3;
      @include mobile {
        padding-top: 20px;
      }
    }

    .yelp,
    .wallStreet {
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
    }
    .highlightsTitle {
      margin-top: 10px;
      font-weight: 600;
      text-align: center;
    }

    .highlightsSubtitle {
      text-align: center;
    }

    .highlight {
      margin-top: 15px;
      margin-bottom: 15px;

      img {
        float: left;
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.titleImage {
  text-align: center;
  width: 124px;
  height: 124px;
  margin: 10px auto 0;

  img {
    width: 124px;
    height: 124px;
  }
}

.message {
  font-size: $sizeML;
  text-align: center;
  margin: 50px auto;
  color: $color-grey1;
}

.contactUs {
  margin: 20px auto;
  font-size: $sizeM;
  text-align: center;
  color: $color-grey1;
}

@import './colors.module.scss';
@import './fonts.module.scss';
@import './mixins.module.scss';
@import './theme.module.scss';

:global {
  /*
   * General
   */

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    background-color: $color-background;
    margin: 0;
    padding: 0;
    height: 100%;
    @include primaryFont();
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  hr {
    border: none;
    height: 4px;
    color: $color-background;
    background-color: $color-background;
  }

  input[type='text']::-ms-clear {
    display: none;
  }

  /*
   * Typography
   */

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    @include theme($themes) {
      color: themeColor('accent');
    }
    text-decoration: none;
  }

  /*
   * Buttons
   */

  button {
    @include primaryFont();
  }

  .btn {
    height: 40px;
    min-width: 150px;
    text-align: center;
    border-radius: 20px;
    font-size: 16px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color 150ms ease-in-out;
    @include primaryFont();
    @include theme() {
      color: themeColor('accent');
    }

    &.disabled {
      cursor: default;
    }

    &.btn-primary {
      @include theme() {
        background-color: themeColor('accent');
      }
      background-color: var(--color-primary-5);
      color: var(--color-white);

      &:is(:hover, :focus, :active):not(:disabled) {
        @include theme() {
          background-color: themeColor('accentDark');
        }
        background-color: var(--color-primary-4);
      }

      &.disabled {
        background-color: var(--utility-gray);
      }
    }

    &.btn-secondary {
      background-color: transparent;
      color: var(--color-primary-5);
      border-color: var(--color-primary-5);

      &:is(:hover, :focus, :active):not(:disabled) {
        background-color: transparent;
        color: var(--color-primary-4);
        border-color: var(--color-primary-4);
      }
    }

    &.btn-success {
      background-color: var(--color-success-4);
      color: var(--color-white);

      &:is(:hover, :focus, :active):not(:disabled) {
        background-color: var(--color-success-3);
      }
    }

    &.btn-text {
      background-color: transparent;
      color: var(--color-primary-5);
      min-width: unset;

      &:is(:hover, :focus, :active):not(:disabled) {
        color: var(--color-primary-4);
        background-color: transparent;
      }
    }

    &.btn-no-style {
      background-color: transparent;
      height: auto;
      text-align: unset;
      min-width: unset;
      padding: 0;
      border: 0;
      border-radius: 0;
      margin: 0;
    }

    &.btn-block {
      display: block;
      width: 100%;
    }
  }

  form .btn-block {
    margin-top: 25px;
  }

  /*
   * Forms
   */

  .form-group {
    position: relative;
    margin-bottom: 15px;

    .form-control-wrapper {
      position: relative;
    }

    .form-control-group {
      display: flex;
      align-items: flex-start;
      margin-left: -5px;
      margin-right: -5px;

      & > .form-group {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 0;

        .form-label {
          opacity: 0;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);
          font-size: 16px;
          color: var(--color-neutral-7);
          pointer-events: none;

          &:after {
            display: none;
          }
        }

        &.empty {
          .form-label {
            opacity: 1;
          }
        }
      }
    }

    .react-tel-input {
      .special-label {
        display: none;
      }
    }

    .form-control,
    .react-tel-input .form-control {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      color: var(--color-neutral-5);
      @include primaryFont();
      padding: 8px 12px;
      font-size: 16px;
      background-color: var(--color-neutral-10);
      border: 1px solid var(--color-neutral-7);
      border-radius: 5px;
      transition: all 150ms ease-in-out;
      line-height: 1.4;
      appearance: none;
      text-align: left;

      &::placeholder {
        color: var(--color-neutral-7);
      }

      &:hover {
        border-color: var(--color-neutral-6);
        background-color: var(--color-white);
      }

      &:focus {
        border-color: var(--color-primary-6);
        background-color: var(--color-white);
      }

      &:is(:disabled, input:read-only, .disabled) {
        color: var(--color-neutral-6);
        border-color: var(--color-neutral-8);
        background-color: var(--color-neutral-9);
        cursor: not-allowed;
        pointer-events: none;
      }

      &.form-control-button {
        min-height: 40px;
        cursor: pointer;

        &:hover {
          border-color: var(--color-primary-6);
        }
      }
    }

    .form-control-static {
      padding: 0 3px;
    }

    .react-tel-input {
      .form-control {
        &:focus {
          box-shadow: 0 0 0 2pt #235fcb;
        }
      }

      .flag-dropdown {
        display: none;
        background-color: transparent;
        border: 0;

        .selected-flag {
          background-color: var(--color-neutral-10);
          border: 1px solid var(--color-neutral-7);
          border-radius: 5px 0 0 5px;
          width: 40px;

          &:focus {
            background-color: var(--color-white);
            border-color: var(--color-primary-7);
            box-shadow: 0 0 0 2pt #235fcb;
          }
        }
      }
    }

    select.form-control {
      padding: 8px 26px 8px 12px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 6px center;
      background-size: 16px 12px;
    }

    .form-control-accessory {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      transition: all 150ms ease-in-out;
      line-height: 1;
      cursor: pointer;

      &.form-control-accessory-button {
        appearance: none;
        background: none;
        border: 0;
      }

      &.password-accessory {
        right: 30px;

        &:hover {
          transform: translateY(-55%);
        }
      }
    }

    .form-control-help,
    .form-control-error {
      padding: 3px 3px 0;
      font-size: 14px;
    }

    .form-control-help {
      padding: 3px 3px 0;
      font-size: 14px;
      color: var(--color-neutral-6);
    }

    .form-control-error {
      padding: 3px 3px 0;
      font-size: 14px;
      color: var(--color-error-5);
      opacity: 0;
      transition: all 150ms ease-in-out;
    }

    .form-label {
      display: inline-block;
      font-size: 14px;
      padding: 5px 3px;
      color: var(--color-neutral-5);
    }

    .form-label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-control-accessory {
        position: relative;
        transform: none;
      }
    }

    &.invalid {
      .form-control {
        border-color: var(--color-error-5);
      }

      .form-control-error {
        opacity: 1;
      }
    }

    &.required {
      .form-label {
        &::after {
          content: '*';
          position: relative;
          left: 2px;
          top: -2px;
          font-size: 75%;
          color: var(--color-error-5);
        }
      }
    }

    .form-control-month {
      width: 40%;
    }

    .form-control-day {
      width: 30%;
    }

    .form-control-year {
      width: 30%;
    }
  }

  /*
   * Utilities
   */

  .hidden {
    display: none;
  }

  .alignLeft {
    text-align: left;
  }

  .alignCenter {
    text-align: center;
  }

  .alignRight {
    text-align: right;
  }

  .floatLeft {
    float: left;
  }

  .floatRight {
    float: right;
  }

  .rotate0 {
    transform: rotate(0deg);
  }

  .rotate90 {
    transform: rotate(90deg);
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .rotate270 {
    transform: rotate(270deg);
  }

  /*
   * Theme
   */

  .themeSVG {
    @include theme() {
      &,
      * {
        fill: themeColor('accent');
        stroke: themeColor('accent');
      }
    }
  }

  .themeSVGFill {
    @include theme() {
      &,
      * {
        fill: themeColor('accent');
      }
    }
  }

  .themeSVGStroke {
    @include theme() {
      &,
      * {
        stroke: themeColor('accent');
      }
    }
  }
}

:export {
  themeHeal: $color-accent;
  themeBlueShield: $blueShield-accent;
}

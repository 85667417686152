@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.box {
  border: 1px solid $color-grey3;
  border-radius: 5px;
}

.noTimeSlots {
  padding: 20px;
  text-align: center;
}

.datePicker {
  margin: 0 auto;
  height: 40px;
  color: $color-grey1;
  background: $color-grey2;
  border-bottom: 1px solid $color-grey3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @include no-select;
  display: flex;
  justify-content: space-between;

  .datePickerArrow {
    width: 40px;
    border: none;
    background: none;
    &:enabled {
      cursor: pointer;
    }
  }

  .buttonContainer {
    align-items: flex-start;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0px 6px;
    font-stretch: 100%;
    line-height: normal;
    text-align: center;

    .datePickerMiddle {
      background: none;
      color: $color-grey1;
      white-space: nowrap;
      font-size: $sizeML;
      @include primaryFont(true);
      border: none;
      height: 100%;
      justify-content: center;

      &:enabled {
        cursor: pointer;
      }

      .iconWrapper {
        display: inline-block;
        svg {
          height: 24px;
          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }

    .datepickerDialog {
      position: absolute;
      clear: both;
      display: contents;
      @include small-320 {
        display: block;
        left: 25px;
        right: 25px;
      }

      .container {
        position: relative;
        border: 0.5px solid rgb(216, 216, 216);
        margin-top: 1px;
        border-radius: 0 0 2px 2px;
        background-color: #fff;
        box-shadow: 1px 1px 10px 3px #d8d8d8;
        z-index: 2;

        @include small-320 {
          left: 2px;
        }
      }
    }
  }
}

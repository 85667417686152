@import '../../styles/mixins.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.bottomSheetWrapper {
  @include transition('opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99)');
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  .partnerHeader {
    border-bottom: 1px solid $color-gray4;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;

    img {
      height: 45px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }

  .bottomSheet {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 85%;
    overflow: auto;
    @include transition('transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99)');
    @include transform('translate3d(0, 100%, 0)');

    .bottomSheetContent {
      max-width: 600px;
      margin: 0 auto;
      background: $color-white;
      position: relative;
      height: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-bottom: 10px;
    }
  }

  .bottomSheetHeader {
    .bottomSheetTitle {
      @include secondaryFont();
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: $sizeXXL;
      font-weight: normal;
      margin: 0;

      @include small-mobile {
        font-size: $sizeL;
      }
    }

    .bottomSheetCloseButton {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;

    .bottomSheet {
      @include transform('translate3d(0px, 0px, 0)');
    }

    .overlay {
      pointer-events: auto;
    }
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  .isPreferred {
    img {
      display: initial;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  .selectButton {
    margin: 20px 20px 0 20px;
  }

  .isPreferred {
    text-align: center;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.button {
  height: 40px;
  line-height: 40px;
  text-align: center;
  @include rounded(20px);
  font-size: $sizeL;
  border: 1px solid transparent;
  cursor: pointer;
  @include secondaryFont();
  @include theme() {
    color: themeColor('accent');
  }
  &.disabled {
    cursor: default;
    @include opacity(0.3);
  }
  &.primary {
    @include theme() {
      background-color: themeColor('accent');
    }
    background-color: $color-accent;
    color: $color-white;
  }
  &.secondary {
    @include theme() {
      color: themeColor('accent');
      border-color: themeColor('accent');
    }
    background-color: $color-white;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  @include desktop {
    min-height: 100vh;
    padding-bottom: 30px;
    background-image: url('../../assets/img/background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50%;
    background-size: cover;

    :global(.theme-blueShield) & {
      background-image: url('../../assets/img/background-bsc.jpg');
    }
  }

  .content {
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $color-white;
    overflow-y: hidden;

    @include mobile {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    @include desktop {
      position: relative;
      max-width: 420px;
      min-height: 600px;
      margin: 45px auto 0;
      padding-top: 20px;
      @include rounded(8px);
      @include box-shadow(5px, 5px, 25px, 0, rgba(179, 179, 179, 0.25));
    }

    &.noMargin {
      margin-top: 0;
    }
  }
}

.pageButton {
  margin-top: 20px;
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.item {
  min-height: 70px;
  position: relative;
  @include rounded(5px);
  display: flex;
  height: 70px;
  justify-content: space-around;
  border: 1px solid $color-grey3;
  margin: 1px 1px 10px 1px;

  &:focus-within {
    outline-color: #4d90fe;
    outline-offset: 1.5px;
    outline-style: auto;
    outline-width: 5px;
  }

  &.selectable {
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
    }

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: #f7f7f7;
    }
  }
  &.selected {
    margin: 1px 0px 10px 0px;
    @include theme() {
      border: 2px solid themeColor('accent');
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .imageContainer {
    align-content: flex-start;
    width: 70px;

    .icon {
      width: 20px;
      height: 20px;
      margin: 0 auto;
    }

    .avatar {
      width: 50px;
      height: 50px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      background-color: $color-grey2;
      color: $color-grey1;
      line-height: 50px;
      text-align: center;
      font-size: $sizeL;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .checkbox {
    padding-left: 10px;

    input[type='checkbox']:focus {
      outline: 0;
    }
  }

  .content {
    flex: 1;
    position: relative;
  }

  .label {
    font-size: $sizeS;
    color: $color-grey1;
  }

  .value {
    font-size: $sizeML;
    color: $color-grey0;
    @include primaryFont();
    display: inline-block;
    max-width: 100%;
  }

  .footer {
    font-size: $sizeM;
    @include theme() {
      color: themeColor('accent');
    }
    @include primaryFont();
  }

  &.patient {
    min-height: 70px;

    .imageContainer {
      width: 70px;
    }

    .content {
      overflow: hidden;

      .label {
        color: $color-grey0;
        font-size: $sizeL;
      }

      .value {
        @include text-overflow();
        font-size: $sizeM;
      }
    }
  }
}

.patientName {
  color: $color-grey0;
  font-size: 16px;
}

.patientInsurance {
  color: $color-grey1 !important;
  font-size: 12px !important;
}

.patientPrice {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 16px !important;
  color: $color-grey0 !important;

  &.active {
    color: $color-accent;
  }
}

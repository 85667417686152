@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/theme.module.scss';

.onDemand {
  margin: 20px 0px;
  text-align: center;
  color: $color-grey0;
  font-size: $sizeL;
  cursor: pointer;

  span {
    @include theme() {
      color: themeColor('accent');
    }
    font-weight: bold;
    display: block;
  }

  .button {
    margin-top: 10px;
  }
}

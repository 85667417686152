@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  margin: 20px 0;
  background-color: $color-white;
  color: $color-grey0;
  border: 1px solid $color-grey9;
  @include rounded(5px);

  &.stateUpcoming {
    .header .status .indicator,
    .etaheader .etastatus .indicator {
      border: 1px solid #66e646;
      background-color: #66e646;
    }
  }

  &.stateActive {
    .header .status .indicator,
    .etaheader .etastatus .indicator {
      border: 1px solid #66e646;
      background-color: #66e646;
    }
  }

  &.stateCompleted {
    .header .status .indicator {
      border: 1px solid #beb09b;
      background-color: #beb09b;
    }
  }

  .header {
    height: 36px;
    line-height: 36px;
    background-color: #f9f9f9;
    @include rounded(5px);

    .date {
      font-size: $sizeM;
      display: inline-block;
      text-align: center;
      padding-left: 10px;
      width: calc(100% - 101px);
      vertical-align: top;
      @include text-overflow();
      @include secondaryFont();
    }

    .status {
      display: inline-block;
      @include secondaryFont();
      text-align: center;
      width: 90px;
      font-size: $sizeS;
      vertical-align: top;
      border-left: 1px solid $color-grey9;

      .indicator {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 6px;
        margin-bottom: -1px;
        @include rounded(5px);
      }
    }
  }

  .etaheader {
    height: 60px;
    line-height: 36px;
    background-color: #f9f9f9;

    .etadate {
      font-size: $sizeM;
      display: inline-block;
      text-align: center;
      padding-left: 10px;
      width: calc(100% - 101px);
      vertical-align: top;
      @include text-overflow();
      @include secondaryFont();
    }

    .etatime {
      @include secondaryFont(true);
      font-size: $sizeML;
      margin-top: -15px;
    }

    .etastatus {
      display: inline-block;
      @include secondaryFont();
      text-align: center;
      width: 90px;
      height: 60px;
      font-size: $sizeS;
      vertical-align: top;
      padding-top: 12px;
      border-left: 1px solid $color-grey9;

      .indicator {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 6px;
        margin-bottom: -1px;
        @include rounded(5px);
      }
    }
  }

  .patientDetails {
    text-align: center;
    margin: 10px;
    @include secondaryFont();
    font-size: $sizeM;

    .visitCode {
      font-size: $sizeXL;
      margin-bottom: 5px;
      @include theme() {
        color: themeColor('accent');
      }
    }

    .partnerLogo {
      width: 40%;
      margin: 0 auto;
    }

    .patientName {
      @include secondaryFont(true);
      font-size: $sizeL;
    }
  }

  .fileUploadContainer {
    border: 1px solid $color-grey9;
    border-radius: 4px;
    padding: 8px;
    margin: 8px;
    font-size: $sizeML;
    min-height: 64px;
    cursor: pointer;

    .imageContainer {
      width: 46px;
      height: 46px;
      @include theme() {
        border: 1px solid themeColor('accent');
      }
      float: left;
      border-radius: 23px;
      margin-right: 10px;
      text-align: center;

      img {
        height: 26px;
        margin-top: 8px;
      }

      &.greenBorder {
        border-color: #45c095;
      }
    }

    .title {
      font-weight: bold;
      margin-top: 2px;
    }

    .cta {
      margin-top: 3px;
      @include theme() {
        color: themeColor('accent');
      }
    }
  }

  .doctorContainer {
    margin: 8px;
    text-align: left;
    vertical-align: center;
    border: 1px solid $color-grey9;
    border-radius: 4px;
    padding: 8px;

    .doctorNameText {
      display: inline-block;
      @include secondaryFont(true);
      font-size: $sizeML;
    }

    .doctorAvatar {
      display: inline-block;
      vertical-align: middle;
      width: 56px;
    }

    .doctorDetails {
      display: inline-block;
      vertical-align: middle;
      width: 70%;
      margin-top: -2px;
    }

    .setPd-button {
      margin: 10px auto 5px auto;
    }

    .readBio {
      padding: 0;
      font-size: $sizeM;
    }

    .pdLabel {
      margin: 0;
      padding-top: 2px;
      margin-top: 0px;
      font-size: $sizeML;
    }

    .pdIcon {
      width: 15px;
      vertical-align: sub;
      margin-right: 2px;
    }
  }

  .actionsContainer {
    margin: 0 10px 10px;

    .action {
      margin-top: 15px;
    }

    .secondaryButton {
      margin-top: 15px;
      background-color: $color-white;
      @include theme() {
        border-color: themeColor('accent');
        color: themeColor('accent');
      }
    }

    .successButton {
      margin-top: 15px;
      display: block;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      font-size: 1em;
      border: 1px solid transparent;
      cursor: pointer;
      font-family: 'Open Sans';
      font-weight: 700;
      background-color: #22b868;
      color: $color-white;

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    .linkAction {
      text-align: center;
      margin: 0 auto;
      display: block;
      width: 40%;
      margin-top: 5px;
    }
  }

  .whatToExpectContainer {
    background: #f6f5f5;
    margin: 10px;
    padding: 10px;
    @include primaryFont();
    @include rounded(5px);
    cursor: pointer;

    .image {
      float: left;
      margin-right: 10px;
    }

    .textContainer {
      .title {
        @include secondaryFont(true);
        margin-bottom: 10px;
        font-size: $sizeM;
      }

      .message {
        color: $color-grey1;
        font-size: $sizeM;
      }

      .button {
        padding-bottom: 0px;
        font-size: $sizeM;

        a,
        a:link,
        a:visited,
        a:hover,
        a:active {
          @include theme() {
            color: themeColor('accentDark');
          }
        }
      }
    }
  }
}

.bottomSheet {
  margin: 20px auto;
  max-width: 280px;

  .bottomMargin {
    margin-bottom: 20px;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.item {
  min-height: 70px;
  position: relative;
  @include rounded(5px);
  display: flex;
  height: 70px;
  justify-content: space-around;
  border: 1px solid $color-grey3;
  margin: 1px 1px 10px 1px;

  &.selectable {
    cursor: pointer;
    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: #f7f7f7;
    }
  }
  &.selected {
    margin: 1px 0px 10px 0px;
    @include theme() {
      border: 2px solid themeColor('accent');
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .imageContainer {
    align-content: flex-start;
    width: 70px;

    .icon {
      width: 20px;
      height: 20px;
      margin: 0 auto;
    }

    .avatar {
      width: 50px;
      height: 50px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      background-color: $color-grey2;
      color: $color-grey1;
      line-height: 50px;
      text-align: center;
      font-size: $sizeL;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .checkbox {
    padding-left: 10px;
  }

  .content {
    flex: 1;
    position: relative;
  }

  .label {
    font-size: $sizeS;
    color: $color-grey1;

    &.required {
      &::after {
        content: '*';
        position: relative;
        left: 2px;
        top: -2px;
        font-size: 75%;
        color: var(--color-error-5);
      }
    }
  }

  .value {
    font-size: $sizeML;
    color: $color-grey0;
    @include primaryFont();
    display: inline-block;
    max-width: 100%;

    &.required {
      color: var(--color-error-5);
    }
  }

  .edit {
    position: absolute;
    right: 6px;
    top: 8px;
    cursor: pointer;
    font-size: $sizeML;
    border: none;
    @include theme() {
      color: themeColor('accent');
    }
    background-color: transparent;
    padding: 0;
    width: 30px;
    text-align: start;
    &:hover {
      text-decoration: underline;
    }
  }

  .caret {
    align-content: flex-end;
    text-align: center;
    width: 50px;
    line-height: 70px;
    svg {
      height: 19px;
      margin: 0 auto;
      width: 11px;
    }
  }

  .footer {
    font-size: $sizeM;
    @include theme() {
      color: themeColor('accent');
    }
    @include primaryFont();
  }

  &.patient {
    min-height: 70px;

    .imageContainer {
      width: 70px;
    }

    .content {
      overflow: hidden;

      .label {
        color: $color-grey0;
        font-size: $sizeL;
      }

      .value {
        @include text-overflow();
        font-size: $sizeM;
      }
    }
  }

  &.service {
    min-height: 70px;

    .imageContainer {
      width: 70px;

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    .content {
      .label {
        color: $color-grey0;
        font-size: $sizeML;
        font-weight: bold;
      }

      .value {
        font-size: $sizeM;
      }
    }
  }

  &.review {
    min-height: 70px;
    margin: 0;
    border-width: 0;

    .imageContainer {
      width: 70px;
    }

    .icon {
      width: 30px;
      height: 30px;
    }

    .label {
      font-size: $sizeS;
      color: $color-grey4;
    }

    .value {
      color: $color-grey0;
      font-weight: bolder;

      &.required {
        color: var(--color-error-5);
      }
    }

    .content {
      padding-right: 60px;
      overflow: hidden;

      .footer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-grey1;
      }
    }
  }
}

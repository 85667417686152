@import './colors.module.scss';

// Theme colors
$blueShield-accent: #0171bc;
$blueShield-accent-darker: #0068a1;
$blueShield-accent-light: #d7e6f4;

$themes: (
  heal: (
    accent: $color-accent,
    accentDark: $color-accent-darker,
    accentLight: $color-accent-light,
  ),
  blueShield: (
    accent: $blueShield-accent,
    accentDark: $blueShield-accent-darker,
    accentLight: $blueShield-accent-light,
  ),
);

@mixin theme($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themeColor($key) {
  @return map-get($theme-map, $key);
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/theme.module.scss';

.headerImageContainer {
  text-align: center;

  .headerImage {
    height: 60px;
    width: 40px;
  }
}

.container {
  width: 90%;
  margin: auto;

  .sliderContainer {
    margin-top: 15px;
    margin-bottom: 20px;

    :global {
      div.slickSlider {
        position: initial;

        .slickPrev {
          left: 10px;
        }

        .slickNext {
          right: 10px;
        }

        ul.slickDots {
          position: absolute;
          width: 80%;
          left: 10%;
          bottom: 20px;
          height: 30px;
          overflow: hidden;
          font-family: sans-serif !important;

          @media (max-width: 480px) {
            position: relative;
            bottom: 0px;
          }

          li {
            button {
              border-radius: 50%;
              width: 10px;
              height: 10px;
              @include theme() {
                background-color: themeColor('accent');
              }
              opacity: 0.5;
              margin: 0 auto;

              &::before {
                content: '';
              }
            }

            &.slickActive {
              button {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .leftArrow {
    margin: auto;
    margin-right: -5px;
  }

  .rightArrow {
    margin: auto;
    margin-left: -5px;
  }
}

.bottomSheetContent {
  margin: 20px auto;
  max-width: 280px;
}

.bottomSheetMessage {
  margin-bottom: 20px;
  color: #545759;
  font-size: $sizeL;
}

.bottomSheetButton {
  margin-bottom: 20px;
}

.noDoctors {
  color: $color-gray2;
  text-align: left;
  margin-top: 20px;
  border: 1px solid $color-grey3;
  padding: 10px;
  border-radius: 5px;
}

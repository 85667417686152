@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.avatar {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  color: $color-grey1;
  line-height: 60px;
  text-align: center;
  font-size: $sizeXL;
  font-weight: bold;
  display: block;
}

.onDemand {
  margin-top: 30px;
  text-align: center;
  color: $color-grey0;
  font-size: $sizeL;

  div {
    margin-top: 10px;
  }
}

.introCard {
  text-align: center;

  img {
    max-width: 150px;
  }

  .text {
    padding: 20px;
    margin-bottom: 10px;
    line-height: 1.5;
    color: $color-grey1;
    font-size: $sizeL;
  }
}

.listContainer {
  .listItem {
    cursor: pointer;
    @include rounded(5px);
    border: 1px solid $color-grey3;
    margin: 1px 1px 10px 1px;
    padding-bottom: 20px;
    width: 100%;

    &.selected {
      margin: 0px 0px 9px 0px;
    }

    .listItemTextContainer {
      vertical-align: middle;
      padding: 0px 10px;
      width: 100%;

      .subtitle {
        color: $color-valid;
        font-size: $sizeXS;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .textTitle {
        font-weight: bold;
        color: $color-gray1;
        @include primaryFont();
        font-size: $sizeL;
        margin-bottom: 15px;
        word-break: break-word;
      }

      .content {
        font-size: $sizeS;
      }
    }

    .rightSection {
      font-size: $sizeS;
      vertical-align: top;
      padding-right: 34px;
      padding-top: 10px;
    }

    .readBio {
      text-align: center;
      font-weight: bold;
      margin: 10px 0;
      @include theme() {
        color: themeColor('accent');
      }
    }

    &.bookCard {
      .listItemTextContainer {
        .subtitle {
          color: #45c095;
          margin-top: 15px;
        }
      }
    }

    &.bookHero {
      .listItemTextContainer {
        &.header {
          background-color: var(--secondary-cyan);
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        .avatarHero {
          float: right;
          width: 100px;
          height: 100px;
          background-size: 100px;
        }

        .textTitle {
          color: white;
          font-weight: 700;
          margin-top: 25px;
          margin-bottom: 0px;
        }

        .subtitle {
          color: white;
          background-color: rgb(46, 47, 65, 0.25);
          display: inline-block;
          padding: 5px;
          margin-bottom: 20px;
        }

        .readBio {
          float: right;
          font-weight: 700;
          margin: 0px;
          margin-right: 30px;
        }

        .content {
          margin-top: 10px;
        }
      }
    }

    &.doctorUnavailable {
      .listItemTextContainer {
        .subtitle {
          color: $color-error;
          margin-top: 15px;
        }
      }
    }
  }
}

.sheetContainer {
  margin: 0 auto 10px auto;
  max-width: 420px;
  padding-left: 10px;
  padding-right: 10px;

  .sheetButton {
    margin: 20px auto 0;
    max-width: 280px;
  }
}

.address {
  font-size: $sizeML;
  color: $color-gray2;
  max-width: 280px;
  margin: 0 auto;
  margin-bottom: 20px;

  span {
    max-width: 260px;
    white-space: nowrap;
    word-wrap: normal;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  img {
    float: right;
    cursor: pointer;
  }
}

.footerButton {
  margin-bottom: -20px;
  margin-top: 10px;
  font-size: $sizeML;
  font-weight: bold;
  @include theme() {
    color: themeColor('accent');
  }
  padding: 15px;
  border-top: 1px solid $color-grey3;

  &:hover {
    background-color: #f7f7f7;
  }

  img {
    float: right;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

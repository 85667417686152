@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.changePatient {
  margin-top: -20px;
  text-align: center;
  display: block;
}

.managePatients {
  margin: 20px auto 10px;
  display: block;
}

.description {
  font-weight: normal;
  text-align: center;
  color: var(--color-neutral-5);
  font-size: 16px;
  margin-bottom: 30px;
}

.submitWrapper {
  margin-top: 5px;
  text-align: center;

  .successMsg {
    color: var(--color-success-3);
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.switchContainer {
  position: relative;
  display: block;
  width: 70px;
  height: 28px;
  padding: 3px;
  background-color: white;
  border-radius: 18px;
  cursor: pointer;
  float: right;
  margin-left: 20px;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.switchInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switchLabel {
  position: relative;
  display: block;
  height: inherit;
  font-size: $sizeM;
  font-weight: 600;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  min-width: 64px;
}

.switchLabel:before,
.switchLabel:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switchLabel:before {
  content: attr(data-off);
  right: 11px;
  color: $color-grey0;
}

.switchLabel:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  opacity: 0;
}

.switchInput:checked ~ .switchLabel {
  @include theme() {
    background: themeColor('accent');
  }
}

.switchInput:checked ~ .switchLabel:before {
  opacity: 0;
}

.switchInput:checked ~ .switchLabel:after {
  opacity: 1;
}

.switchHandle {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 22px;
  height: 22px;
  background: white;
  border-radius: 50%;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switchHandle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.switchInput:checked ~ .switchHandle {
  left: 42px;
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.button {
  font-size: $sizeML;
  @include primaryFont();
  padding: 10px 0;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

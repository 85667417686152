@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--color-neutral-9);
  position: relative;

  .content {
    width: 500px;
    max-width: 95%;
    margin: 0 auto;
    background: var(--color-white);
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .img {
      max-width: 225px;
      margin: 0 auto;
    }

    h1 {
      font-size: $sizeXXL;
      font-weight: bold;
      color: var(--color-ui-neutral-4);
      margin: 0;
    }

    p {
      font-size: $sizeL;
      line-height: 1.5;
      color: var(--color-ui-neutral-6);
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}

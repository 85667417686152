@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.notificationBox {
  @include rounded(5px);
  margin: 10px auto 10px auto;
  padding: 10px;
  width: 100%;
  font-size: $sizeMM;
  color: $color-grey0;
  @include primaryFont();

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    line-height: 1;

    .title {
      font-weight: bold;
      font-size: $sizeL;
    }

    .icon {
      width: 15px;
      margin-right: 5px;
      margin-top: 1px;
    }
  }

  &.error {
    .title {
      color: var(--semantic-red);
    }

    color: var(--semantic-red);
    background-color: var(--semantic-pale-red);

    & a:link {
      color: var(--semantic-red);
    }
  }

  &.warning {
    .title {
      color: var(--semantic-orange);
    }

    background-color: var(--semantic-pale-orange);
  }

  &.info {
    color: var(--semantic-blue);
    font-weight: 300;

    @include theme() {
      background-color: var(--semantic-pale-blue);
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: var(--semantic-blue);
      font-weight: 700;
    }
  }

  &.success {
    color: var(--semantic-green);
    background-color: var(--semantic-pale-green);
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}

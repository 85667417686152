$sizeXS: 0.625em;
$sizeS: 0.688em;
$sizeM: 0.75em;
$sizeMM: 0.813em;
$sizeML: 0.875em;
$sizeL: 1em;
$sizeXL: 1.125em;
$sizeXXL: 1.25em;
$sizeXXXL: 1.5em;

@font-face {
  font-family: 'Museo Sans';
  font-weight: 100;
  src: url(../fonts/MuseoSans/Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  src: url(../fonts/MuseoSans/Light.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  src: url(../fonts/MuseoSans/Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  src: url(../fonts/MuseoSans/Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  src: url(../fonts/MuseoSans/Bold.otf) format('opentype');
}

@mixin primaryFont($bold: false) {
  font-family: 'Museo Sans', sans-serif, Helvetica;
  @if $bold {
    font-weight: 700;
  }
}

@mixin secondaryFont($bold: false) {
  font-family: 'Museo Sans';
  @if $bold {
    font-weight: 700;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/theme.module.scss';

.listContainer {
  .listItem {
    cursor: pointer;
    border: 1px solid $color-grey2;
    border-bottom: 1px solid transparent;
    background-color: $color-grey7;
    width: 100%;

    &:last-child {
      border-bottom: 1px solid $color-grey2;
    }

    .listItem-arrow {
      width: 30px;
    }

    &.active {
      background-color: $color-white;
      @include theme() {
        border-color: themeColor('accent');
      }

      .listItem-image {
        background: $color-white;
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    .listItem-image {
      vertical-align: middle;
      text-align: center;
      background: $color-grey5;
      width: 70px;
      height: 80px;
    }

    .listItem-text-container {
      vertical-align: middle;
      padding: 0 10px;

      .textTitle {
        color: $color-grey1;
        @include primaryFont(true);
        font-size: $sizeML;
      }

      .textValue {
        color: $color-grey1;
        @include primaryFont();
        font-size: $sizeM;
      }
    }
  }
}

.sheetContainer {
  margin: 20px auto;
  max-width: 280px;

  .sheetButton {
    margin-top: 20px;
  }
}

.highDemandContainer {
  text-align: center;
  margin: 10px 20px;

  .textTitle {
    color: $color-grey0;
    @include primaryFont(true);
    font-size: $sizeL;
    margin-bottom: 10px;
  }

  .textValue {
    color: $color-grey1;
    @include primaryFont();
    font-size: $sizeML;
  }
}

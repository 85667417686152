@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

// Font sizes in geosuggest input should be 16 px or larger to prevent iOS/Safari undesired zooming behavior.
:global {
  .geosuggest {
    font-size: $sizeL;
    position: relative;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;

    input[type='text'] {
      background-color: var(--color-neutral-10);
      border: 1px solid var(--color-neutral-7);
      border-radius: 5px;
      -webkit-appearance: none;

      box-sizing: border-box;
      color: $color-grey1;
      @include primaryFont();
      font-size: 16px;
      height: 40px;
      // 50px of right padding to ensure the clear button doesn't obscure the text.
      padding: 8px 50px 8px 12px;
      width: 100%;

      &.empty {
        border-color: $color-grey3;
        background-color: $color-grey5;
      }

      &:focus,
      &.empty:focus {
        background-color: $color-white;
      }
    }
  }

  .geosuggest__suggests {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -6px;
    background: $color-white;
    border-style: solid;
    border-width: 1px;
    @include theme() {
      border-color: themeColor('accent');
    }
    border-radius: 5px;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    border-left: 0;
    border-right: 0;
    font-size: $sizeL;
    padding: 0.5em 0.65em;
    cursor: pointer;
    color: $color-grey1;
  }

  .geosuggest__item--active {
    @include theme() {
      background: themeColor('accent');
    }
    color: $color-white;
  }

  .geosuggest__item:hover:not(.geosuggest__item--active),
  .geosuggest__item:focus:not(.geosuggest__item--active) {
    @include theme() {
      background: themeColor('accent');
    }
    color: $color-grey2;
  }

  .geosuggest__item__matchedText {
    font-weight: bold;
  }
}

.container {
  textarea {
    @include primaryFont();
    font-size: $sizeML;
  }

  .suggestContainer {
    position: relative;

    .clearButton {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      border: none;
      background: var(--color-neutral-10);
    }
  }

  .addressItem {
    .image {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }

    .text {
      display: inline-block;
      margin-top: 5px;
      vertical-align: top;
      @include primaryFont();
      color: $color-grey0;
    }
  }
}

.addressTypeLabel {
  text-align: center;
  color: $color-gray2;
  font-size: $sizeML;
  margin-top: 15px;
  margin-bottom: 5px;
}

.label {
  display: inline-block;
  font-size: 14px;
  padding: 5px 3px;
  color: var(--color-neutral-5);
  &.required {
    &::after {
      content: '*';
      position: relative;
      left: 1px;
      top: -2px;
      font-size: 75%;
      color: var(--color-error-5);
    }
  }
}

.inputContainer {
  margin-top: 0;
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  text-align: center;

  .titleImage {
    margin: 10px auto 0;

    img {
      width: 124px;
      height: auto;
    }
  }

  .button {
    display: block;
    width: fit-content;
    @include theme() {
      background: themeColor('accent');
    }
    color: $color-white !important;
    height: 40px;
    line-height: 40px;
    @include rounded(20px);
    font-size: $sizeL;
    min-width: 240px;
    margin: 30px auto 5px;
  }

  .link {
    @include theme() {
      color: themeColor('accent');
    }
    font-size: $sizeML;
    background: none !important;
    display: inline-block;
    padding: 0;
    border: 0;
    outline: none;
  }

  .content {
    margin-top: -20px;
    text-align: center;
  }
}

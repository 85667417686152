@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.browserCompatibility {
  text-align: center;
  color: $color-grey1;
  margin: -30px auto 30px;
  max-width: 360px;
}

.contact {
  text-align: center;
  @include secondaryFont();
  font-size: $sizeML;
  color: $color-grey1;
  margin: 15px auto;
}

.buttonWrapper {
  text-align: center;

  .button {
    border: 0;
    @include theme() {
      background-color: themeColor('accent');
    }
    color: $color-white;
    @include rounded(99px);
    padding: 0 1.5em;
    height: 35px;
    line-height: 36px;
    font-size: $sizeL;
    margin: 15px auto;
  }
}

@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/theme.module.scss';

.header {
  text-align: center;

  .titleImage {
    width: 124px;
    height: 124px;
    margin: 10px auto 0;

    img {
      width: 124px;
      height: 124px;
    }
  }

  .secondaryInfo {
    @include primaryFont();
    font-size: $sizeML;
    color: $color-grey0;
    padding-top: 20px;
  }
}

.forgot {
  margin-top: 15px;
  text-align: center;
  font-size: $sizeM;
}

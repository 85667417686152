@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.table {
  width: 100%;

  .cell {
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: $color-white;
    color: $color-grey1;
    border: 1px solid $color-grey2;
    font-size: $sizeML;
    @include primaryFont();
    height: 50px;

    @include small-mobile {
      font-size: $sizeM;
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }

    &.selected {
      cursor: default;
      color: $color-white;
      @include theme() {
        background-color: themeColor('accent');
        border-color: themeColor('accent');
      }
    }
  }
}

@import '../../styles/fonts.module.scss';

.checkbox {
  overflow: hidden;

  div {
    float: left;
  }

  .leftClass {
    width: 5%;
  }

  .rightClass {
    width: 95%;
    padding-left: 8px;
    text-align: justify;
  }

  .rightClass-login {
    padding-top: 2px;
    margin-left: 0px;
  }

  label {
    font-size: $sizeS;
  }
}

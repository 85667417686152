@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  display: flex;
  margin-top: 20px;

  .bar {
    border-bottom: 1px solid $color-grey3;
    height: 10px;
    flex: 1;
  }

  .orLabel {
    font-size: $sizeM;
    color: $color-gray2;
    line-height: 20px;
    padding: 0 10px;
  }
}

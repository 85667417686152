@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/theme.module.scss';

.stepsContainer {
  position: relative;
  margin-right: 20px;
  height: 50px;

  .stepDot {
    position: absolute;
    background-color: #f7f7f7;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    z-index: 2;

    &.selected {
      @include theme() {
        background-color: var(--secondary-cyan);
      }
      cursor: pointer;
    }

    &.current {
      cursor: default;
      width: 11px;
      height: 11px;
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
  }

  .stepLabel {
    position: absolute;
    font-size: $sizeS;
    color: $color-grey1;
    width: 60px;
    margin-left: -20px;
    text-align: center;
    top: 25px;

    &.selected {
      @include theme() {
        color: themeColor('accent');
      }
      cursor: pointer;

      &.current {
        cursor: default;
        color: var(--primary-gray);
      }
    }
  }

  .stepBar {
    position: absolute;
    height: 5px;
    background-color: #f7f7f7;
    top: 3px;
    margin-left: 10px;
    margin-right: -10px;
    z-index: 1;

    &.selected {
      @include theme() {
        background-color: var(--secondary-cyan);
      }
    }
  }

  div:nth-of-type(2) .stepBar {
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
  }
  div:last-of-type .stepBar {
    border-top-right-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.85);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
}

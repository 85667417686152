@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';

.container {
  color: $color-grey1;
  margin: 60px auto;
  max-width: 300px;
  text-align: center;

  .image {
    &.grayscale {
      @include grayscale();
      @include opacity(0.4);
    }
    margin-bottom: 10px;

    img {
      width: 256px;
    }
  }

  .text {
    @include secondaryFont(true);
    font-size: $sizeXXL;
  }

  .textSecondary {
    margin-top: 10px;
    @include secondaryFont();
  }
}

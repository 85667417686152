@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.fileContainer {
  padding: 10px;

  .fileInner {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;

    .fileIcon {
      width: 30px;
      height: auto;
      margin-left: 10px;
      margin-right: 10px;
    }

    .fileTitle {
      font-weight: bold;
    }
  }
}

.bottomSheetContent {
  margin: 0 auto;
  max-width: 320px;
  padding-bottom: 20px;

  .description {
    margin-bottom: 15px;
  }

  .forgotPassword {
    display: block;
    margin-left: auto;
    font-size: 14px;
  }
}

.email {
  padding-top: 10px;
  color: $color-gray2;
  pointer-events: none;
  font-size: $sizeS !important;
  @include primaryFont();
}

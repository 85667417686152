@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';

.image {
  margin: 15px auto 0;
  max-width: 200px;

  img {
    width: 100%;
  }
}

.agreementList {
  margin: 40px auto;
  padding: 0 10px;

  .agreement {
    color: $color-grey0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;

    label {
      font-size: $sizeML;
      display: block;
    }
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.action {
  margin-top: 15px;
  clear: both;
}

.secondaryAction {
  background: white;
  @include theme() {
    border: 1px solid themeColor('accent');
    color: themeColor('accent');
  }
}

.bookContainer {
  text-align: center;
  color: $color-grey0;

  .logo {
    max-width: 80%;
    max-height: 100px;
    margin-bottom: 10px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 12px;
  }

  .subtitle {
    @include secondaryFont();
    font-weight: bold;
  }
}

.details {
  padding: 20px 0px;

  .line {
    padding-top: 10px;
    clear: both;

    .key {
      float: left;
      font-weight: bold;
      color: $color-grey0;
      text-align: left;
    }

    .value {
      float: right;
      color: $color-grey0;
      text-align: right;
      max-width: 70%;
    }
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
}

.card {
  margin: 0 20px;
  padding-top: 20px;
  @include secondaryFont();

  &.first {
    border-bottom: 1px solid $color-gray4;
  }

  .text {
    color: $color-grey11;
    text-align: center;

    .textAuthor {
      margin-top: -3px;
    }

    &.yelp {
      font-style: italic;
    }
  }

  .yelp,
  .wallStreet {
    display: block;
    margin: 10px auto;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  .card {
    @include secondaryFont();
    color: $color-grey0;

    .divider {
      border-top: 1px solid $color-grey3;
      width: 60%;
      margin: 0 auto;
    }

    .section {
      .image {
        text-align: center;
        margin-top: 20px;
      }

      .title {
        text-align: center;
        font-size: $sizeXXL;
        font-weight: normal;
      }

      .bullet {
        margin: 20px auto;
        width: 80%;
        font-size: $sizeML;
      }
    }
  }
}

.badges {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 15px;

  .badge {
    width: 50%;
    text-align: center;
    padding: 5px;

    img {
      width: 100%;
    }
  }
}

@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/theme.module.scss';

.header {
  text-align: center;

  .titleImage {
    width: 124px;
    height: 124px;
    margin: 10px auto 0;

    img {
      width: 124px;
      height: 124px;
    }
  }

  .registerHeader {
    @include primaryFont();
    font-size: $sizeXXL;
    color: $color-grey0;
    padding-left: 58px;
    padding-right: 58px;
    padding-top: 15px;
  }
}

.info {
  padding-top: 10px;
  @include primaryFont();
  font-size: $sizeMM;
  text-align: center;
  color: $color-grey0;
}

.alreadyHaveAccount {
  text-align: center;
  font-size: $sizeM;
  @include secondaryFont();
  @include theme() {
    color: themeColor('accent');
  }
  padding-top: 20px;
}

.contactUs {
  margin-top: 10px;
  text-align: center;
  font-size: $sizeM;
  @include secondaryFont();
  margin-bottom: 30px;

  .privacyText {
    color: $color-grey1;
  }
}

@import '../../styles/fonts.module.scss';
@import '../../styles/colors.module.scss';
@import '../../styles/mixins.module.scss';

.content {
  margin: 20px auto;
  max-width: 280px;

  .header {
    @include primaryFont();
    font-size: $sizeML;
    margin-bottom: 20px;
    color: $color-grey0;
    text-align: center;
  }

  .buttonPrimary {
    margin-bottom: 10px;
  }

  .buttonSecondary {
    margin-top: 10px;
  }
}

.sheetContainer {
  margin: 0 auto 20px auto;
  max-width: 420px;
  padding-left: 10px;
  padding-right: 10px;

  .sheetButton {
    margin: 20px auto 0;
    max-width: 280px;
  }
}

.select {
  select: {
    appearance: auto !important;
    background-image: none !important;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.bar {
  border-bottom: 2px solid $color-grey5;
  height: 10px;
  margin-bottom: 10px;
}

.loginContent {
  margin-top: 40px;
  margin-bottom: 40px;

  .image {
    width: 155px;
    margin: auto;
  }

  .label {
    margin: 20px auto;
    width: 80%;
    text-align: center;
    font-size: $sizeML;
    color: $color-grey1;

    a {
      color: #007acc;
    }
  }

  .loginButton {
    background-color: #007acc !important;
    margin: 20px;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  margin: 15px auto 0;
  max-width: 280px;
  padding-bottom: 20px;
  @include desktop {
    max-width: 356px;
  }

  .visitSummaryContainer {
    background-color: #f6f5f5;
    text-align: center;
    padding: 15px;
    margin-bottom: 25px;

    .title {
      @include secondaryFont(true);
      font-size: $sizeML;
    }

    .time {
      @include secondaryFont();
      font-size: $sizeML;
    }
  }

  .text {
    @include primaryFont();
    text-align: center;
    font-size: $sizeML;
    margin: 10px;
  }

  .rescheduleButton {
    display: block;
    width: 100%;
    margin-bottom: 25px;
  }

  .button {
    margin-bottom: 15px;
  }

  .reasonOptions {
    border: 0;
    padding: 0;
    margin: 15px 0;

    .reasonOption {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 8px 20px;
      border-radius: 99px;
      border: 1px solid var(--color-neutral-6);
      transition: border-color 150ms ease-in-out;

      &.selected,
      &:hover {
        border-color: var(--color-primary-5);
      }

      input {
        margin: 0 10px 0 0;
      }
    }
  }

  .notesContainer {
    margin-bottom: 15px;
  }

  .bookAnotherVisitButton {
    margin-top: 20px;
  }
}

@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';
@import '../../styles/mixins.module.scss';
@import '../../styles/theme.module.scss';

.container {
  .text {
    text-align: center;
  }

  .separator {
    height: 1px;
    background: $color-grey9;
  }

  .submitButton {
    display: block;
    width: 100%;
    margin: 20px auto;
  }
}

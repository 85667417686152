@import '../../styles/colors.module.scss';
@import '../../styles/fonts.module.scss';

.container {
  .contactContainer {
    @include secondaryFont();
    font-size: $sizeML;
    color: $color-grey1;

    .contact {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
